export default function resolveElementNgScope(elOrSelector) {
    elOrSelector = elOrSelector instanceof HTMLElement
        ? elOrSelector 
        : document.getElementById(elOrSelector)
        ?? document.querySelector(elOrSelector)
        ?? null

    if(! (elOrSelector instanceof HTMLElement)) {
        return null;
    }

    console.log({
        context: 'resolveElementNgScope',
        elOrSelector,
    });

    if (! elOrSelector.className.includes('ng-scope')) {
        return resolveElementNgScope(elOrSelector.parentElement);
    }

    return angular.element(elOrSelector).scope();
}