import injectCSS from './libs/injectCSS';
import flattenMenus from './libs/flattenMenus';
import resolveElementNgScope from './libs/resolveElementNgScope';
import makeCSS from './libs/makeCSS';
import appendStyle from './libs/appendStyle';
import P21CommandPalette from './libs/P21CommandPalette';
import AppVersion from './libs/version';

makeCSS();

new P21CommandPalette;

// import { map } from 'lodash';
// import FuzzySearch from 'fuzzy-search';
// import Fuse from 'fuse.js';
import MiniSearch from 'minisearch';

function initializeCommandPalette() {
    if (typeof window.P21CommandPalette === 'undefined') {
        window.P21CommandPalette = {
            init: {
                maxAttempts: 10,
                retryDelay: 250,
                attemptCount: 0,
            },
            injected: false,
            version: AppVersion,
        };
    }
}

const addP21CommandPalette = () => {
    initializeCommandPalette();
    const P21CommandPalette = window.P21CommandPalette;

    P21CommandPalette.init.attemptCount++;
    P21CommandPalette.init.retryDelay += P21CommandPalette.init.attemptCount == 1
        ? 0
        : 100;

    const P21CommandPaletteNotification = (message, timeoout = 5000) => {
        const elId = 'P21CommandPaletteNotification';

        if (document.getElementById(elId) !== null) {
            document.getElementById(elId).remove();
        }

        const notificationEl = document.createElement('div');
        notificationEl.id = elId;
        notificationEl.innerHTML = `<div>${message}</div>`;

        notificationEl.addEventListener('transitionend', event => {
            if (notificationEl.classList.contains('fade-out')) {
                notificationEl.remove();

                return;
            }
            setTimeout(() => {
                notificationEl.classList.add('fade-out');
            }, timeoout);
        });
        setTimeout(() => notificationEl.classList.add('fade-in'), 0);
        
        // injectCSS('#P21CommandPaletteNotification', `
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     margin-top: 32px;
        //     margin-right: 16px;
        //     background-color: black;
        //     border-radius: 6px;
        //     color:white;
        //     z-index: 9999;
        //     padding: 8px;
        //     opacity: 0;
        // `);
        injectCSS('#P21CommandPaletteNotification', `
            position: absolute;
            top: 0;
            right: 0;
            background-color: black;
            border-bottom-left-radius: 6px;
            color:white;
            z-index: 9999;
            padding: 4px;
            font-size: 11px;
            opacity: 0;
            padding-left: 12px;
            padding-right: 12px;
        `);

        injectCSS('#P21CommandPaletteNotification.fade-in', `
            visibility: visible;
            opacity: 1;
            transition: opacity 2s linear;
        `);

        injectCSS('#P21CommandPaletteNotification.fade-out', `
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s 2s, opacity 2s linear;
        `);

        injectCSS('@keyframes fadeIn', `
            @keyframes fadeIn {
                0% {opacity:0;}
                100% {opacity:1;}
            }
        `);

        document.body.prepend(notificationEl);
    };

    const makeDialog = () => {
        const bgEl = document.createElement('div');
        bgEl.id = 'p21-command-palette-dialog--background';
        bgEl.style = `
            position: absolute; 
            top: 0; 
            right: 0; 
            bottom: 0; 
            left: 0; 
            background-color: rgba(0,0,0,0.25); 
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center
        `;

        const dialogContainerEl = document.createElement('div');
        dialogContainerEl.id = 'p21-command-palette-dialog--container';
        dialogContainerEl.style = `
            color: white;
            min-width: 25vw;
            min-height: 25vh;
            background-color: rgba(0,0,0, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
                    0 2px 4px rgba(0,0,0,0.07), 
                    0 4px 8px rgba(0,0,0,0.07), 
                    0 8px 16px rgba(0,0,0,0.07),
                    0 16px 32px rgba(0,0,0,0.07), 
                    0 32px 64px rgba(0,0,0,0.07);
        `;

        bgEl.appendChild(dialogContainerEl);
        dialogContainerEl.innerHTML = '<h1>Dialog</h1>';
        dialogContainerEl.role = 'dialog';

        document.body.prepend(bgEl);
        dialogContainerEl.__dialog = {
            destroy() {
                bgEl.remove();
            },
        };
    };

    const debounce = (callback, wait) => {
      let timeoutId = null;
      return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
          callback.apply(null, args);
        }, wait);
      };
    }

    const loadFuse = (callback) => {
      const existingScript = document.getElementById('scriptId');

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/fuse.js/dist/fuse.js'; // URL for the third-party library being loaded.
        script.id = 'commandPaletteFuse'; // e.g., googleMaps or stripe
        document.body.appendChild(script);

        script.onload = () => {
          if (callback) callback();
        };
      }

      if (existingScript && callback) callback();
    };

    const appElSelector = '.ng-scope.ep-shell-menu-container';

    const appEl = document.querySelector(appElSelector);


    if(typeof appEl === 'undefined') {
        if(P21CommandPalette.init.attemptCount > P21CommandPalette.init.maxAttempts) {
            console.error('[appEl] Unable to load P21CommandPalette!');
            return;
        }

        console.warn(`[appEl] (${P21CommandPalette.init.attemptCount}) P21 is not ready yet, will try in ${P21CommandPalette.init.retryDelay}ms…`);

        setTimeout(addP21CommandPalette, P21CommandPalette.init.retryDelay);
        return ;
    }

    const appScope = angular.element(appEl).scope();
    if(typeof appScope === 'undefined') {
        if(P21CommandPalette.init.attemptCount > P21CommandPalette.init.maxAttempts) {
            console.error('[appScope] Unable to load P21CommandPalette!');
            return;
        }

        console.warn(`[appScope] (${P21CommandPalette.init.attemptCount}) P21 is not ready yet, will try in ${P21CommandPalette.init.retryDelay}ms…`);

        setTimeout(addP21CommandPalette, P21CommandPalette.init.retryDelay);
        return ;
    }

    const menus = appScope.menuOptions;

    if(typeof menus === 'undefined') {
        if(P21CommandPalette.init.attemptCount > P21CommandPalette.init.maxAttempts) {
            console.error('[menus] Unable to load P21CommandPalette!');
            return;
        }

        console.warn(`[menus] (${P21CommandPalette.init.attemptCount}) P21 is not ready yet, will try in ${P21CommandPalette.init.retryDelay}ms…`);

        setTimeout(addP21CommandPalette, P21CommandPalette.init.retryDelay);
        return ;
    }

    const menusFlat = flattenMenus(menus.menu.menuitems);

    console.log('Loading P21 Command Palette…');
    window.P21CommandPalette.helpers = {
            debounce,
            loadFuse,
            injectCSS,
            flattenMenus,
            resolveElementNgScope,
    };

    // Create Classes




    console.log({
        appElSelector,
        appEl,
        appScope,
        menus,
        menusFlat,
    })

    if(typeof window.P21CommandPalette === 'undefined') {
        window.P21CommandPalette = {};
    }

    window.P21CommandPalette.app = {
        appElSelector,
        appEl,
        appScope,
        menus,
        menuItems: menus.menu.menuitems,
        menusFlat,
    }

    const parentEl = document.body;
    const wrapperEl = document.createElement('div');
    wrapperEl.id = 'command-palette--wrapper';
    const containerEl = document.createElement('div');
    containerEl.id = 'command-palette--container';
    const commandPaletteEl = document.createElement('div');
    commandPaletteEl.id = 'command-palette--body';
    const commandPaletteHeaderEl = document.createElement('div');
    const commandPaletteResultsEl = document.createElement('div');
    commandPaletteResultsEl.id = 'command-palette--results';
    const commandPaletteSearchEl = document.createElement('div');
    commandPaletteSearchEl.id = 'command-palette--search';
    const commandPaletteSearchInputEl = document.createElement('input');
    commandPaletteSearchInputEl.id = 'command-palette--search--input';
    const commandPaletteSearchStatusEl = document.createElement('div');
    commandPaletteSearchStatusEl.id = 'command-palette-search--status';

    commandPaletteResultsEl.classList.add('palette-results');
    commandPaletteHeaderEl.classList.add('palette-header');
    commandPaletteHeaderEl.innerHTML = 'Command Palette';
    commandPaletteSearchStatusEl.innerHTML = '';

    commandPaletteSearchInputEl.type = "search";

    commandPaletteSearchEl.appendChild(commandPaletteSearchInputEl);
    commandPaletteSearchEl.appendChild(commandPaletteSearchStatusEl);

    commandPaletteEl.appendChild(commandPaletteHeaderEl);
    commandPaletteEl.appendChild(commandPaletteSearchEl);
    commandPaletteEl.appendChild(commandPaletteResultsEl);
    containerEl.appendChild(commandPaletteEl);
    wrapperEl.appendChild(containerEl);
    parentEl.prepend(wrapperEl);

    commandPaletteEl.addEventListener('keydown', function(event) {
        console.log({
            message: 'commandPaletteEl:listening to keydown',
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
            key: event.key,
        });

        if (event.key.toLowerCase() === "arrowup") {
            event.preventDefault();
            commandPaletteEl.classList.add('animate', 'shake');
            setTimeout(() => {
                commandPaletteEl.classList.remove('animate', 'shake');    
            }, 500);
            console.log('🠁: Not implemented');
        }
        if (event.key.toLowerCase() === "arrowdown") {
            event.preventDefault();
            commandPaletteEl.classList.add('animate', 'shake');
            setTimeout(() => {
                commandPaletteEl.classList.remove('animate', 'shake');    
            }, 500);
            console.log('🠃: Not implemented');
        }

        if (event.key.toLowerCase() === "enter") {
            event.preventDefault();
            event.target.__P21CommandMenuItem.action();
            wrapperEl.style.display = 'none';
            return;
            commandPaletteEl.classList.add('animate', 'shake');
            setTimeout(() => {
                commandPaletteEl.classList.remove('animate', 'shake');    
            }, 500);
            console.log('Enter: Not implemented');

        }

        if (event.key.toLowerCase() === "escape") {
            // event.preventDefault();
            event.stopPropagation();
            // event.stopImmediatePropagation();
            console.log({
                context: 'commandPaletteEl: Hiding command palette',
                target: {
                    id: event.target.id,
                    el: event.target,
                },
                currentTarget: {
                    id: event.currentTarget === null
                        ? null
                        : event.currentTarget,
                    el: event.currentTarget,
                },
                event,
            });
            commandPaletteSearchInputEl.focus();
            return false;
        }
    });

    parentEl.addEventListener('keydown', function(event) {
        console.log({
            message: 'parentEl:listening to keydown',
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
            key: event.key,
        });

        if (event.ctrlKey && event.shiftKey && event.key.toLowerCase() === 'p') {
            console.log('Togglingcommand palette');

            if (wrapperEl.style.display == 'block') {
                wrapperEl.style.display = 'none';
                return;
            }

            wrapperEl.style.display = 'block';
            commandPaletteSearchInputEl.focus();
        }

        if (event.key.toLowerCase() === "escape") {
            console.log({
                context: 'parentEl: Hiding command palette',
                target: {
                    id: event.target.id,
                    el: event.target,
                },
                currentTarget: {
                    id: event.currentTarget === null
                        ? null
                        : event.currentTarget,
                    el: event.currentTarget,
                },
                event,
            });

            wrapperEl.style.display = 'none';
        }
    });

    commandPaletteSearchInputEl.addEventListener('keydown', debounce(event => {
        if (event.key.toLowerCase() === "escape") {
            console.log({
                context: 'parentEl: Hiding command palette',
                target: {
                    id: event.target.id,
                    el: event.target,
                },
                currentTarget: {
                    id: event.currentTarget === null
                        ? null
                        : event.currentTarget,
                    el: event.currentTarget,
                },
                event,
            });
            wrapperEl.style.display = 'none';
            return;
        }
        const showAll = event.target.value.trim() == '';
        const resultsEl = document.getElementById('')

        const matchedMenus = window.P21CommandPalette.fuse
            .search(event.target.value)
            .map(m => m.item.id);

        let counter = 0;
        let hiddenCount = 0;
        console.log({
            context: 'commandPaletteSearchInputEl',
            key: event.key,
            value: event.target.value,
            showAll,
        });

        for(const menu of window.P21CommandPalette.filteredMenus) {
            if(matchedMenus.includes(menu.id) || showAll) {
                if(menu.el instanceof HTMLElement) {
                    menu.el.classList.remove('result-hidden');
                    counter++;
                    if (counter % 2 === 0) {
                        menu.el.classList.add('result-even');
                    } else {
                        menu.el.classList.remove('result-even');
                    }
                }
            } else {
                if(menu.el instanceof HTMLElement) {
                    hiddenCount++;
                    if(hiddenCount < 11) {
                        console.log({
                            context: 'hiding menu…',
                            el: menu.el,
                            id: menu.id,
                            classList: menu.el.classList,
                            isHTMLElment: menu.el instanceof HTMLElement,
                        });
                    }
                    try {
                        menu.el.classList.add('result-hidden');
                    } catch(err) {
                        if(hiddenCount < 11) {
                            console.warn({err})
                        }
                    }
                    menu.el.classList.remove('result-even');
                }
            }
        }

        document.getElementById('command-palette-search--status')
            .innerHTML = showAll
                ? ''
                : `${counter} results`;
    }, 500));

    if (typeof window.P21CommandPalette.filteredMenus === 'undefined') {
        window.P21CommandPalette.filteredMenus = [];
    }

    // Add Close Menu Item
    const closeWindowEl = document.querySelector(`[ng-click*="Quick.Close"]`);
    // Add Logout Menu Item
    const logoutEl = document.querySelector('.fa-sign-out');

    if (closeWindowEl instanceof HTMLElement) {
        // Title Mutation Observer
        // select the target node
        var target = document.querySelector('title');

        // create an observer instance
        var observer = new MutationObserver(function(mutations) {
            // We need only first event and only new value of the title
            console.log({MUTATED: mutations[0].target.innerText});
            document.getElementById('command-palette-result--description-close_window')
                .innerText = `Close ${mutations[0].target.innerText}`;
        });

        // configuration of the observer:
        var config = { subtree: true, characterData: true, childList: true };

        // pass in the target node, as well as the observer options
        observer.observe(target, config);

        window.P21CommandPalette.app.menusFlat.unshift({
            action() {
                closeWindowEl.click();
            },
            caption: `Close Window`,
            id: 'close_window',
            get description() {
                return `Close ${document.title}`;
            },
            searchTerm: 'close',
        });
    } else {
        console.warn('Unable to resolve close window…');
    }

    if (logoutEl instanceof HTMLElement) {
        window.P21CommandPalette.app.menusFlat.unshift({
            action() {
                logoutEl.click();
            },
            caption: `Logout`,
            id: 'logout',
            description: 'Logout of P21',
            searchTerm: 'close',
        });
    }

    // Enable / Disable Network Printing
    window.P21CommandPalette.app.menusFlat.unshift({
        action() {
            try {
                const p21Profiles = JSON.parse(localStorage.getItem('P21.Profiles'));

                for(const profile of p21Profiles) {
                    console.log(profile);
                    profile.printMode = 'network';
                    profile.networkPrinter = 'qz-print.theperfumery.com';
                }

                localStorage.setItem('P21.Profiles', JSON.stringify(p21Profiles));
            } catch (error) {
                console.warn('Unable to parse profiles');
            }
        },
        caption: `Enable Network Printing`,
        id: 'printer-network-enable',
        description: 'Enables network printing, will require logging out…',
        searchTerm: 'enable network printing',
    });

    window.P21CommandPalette.app.menusFlat.unshift({
        action() {
            try {
                const p21Profiles = JSON.parse(localStorage.getItem('P21.Profiles'));

                for(const profile of p21Profiles) {
                    profile.printMode = 'browser';
                    profile.networkPrinter = 'qz-print.theperfumery.com';
                }

                localStorage.setItem('P21.Profiles', JSON.stringify(p21Profiles));
            } catch (error) {
                console.warn('Unable to parse profiles');
            }
        },
        caption: `Disable Network Printing`,
        id: 'printer-network-disable',
        description: 'Disables network printing, will require logging out…',
        searchTerm: 'disable network printing',
    });

    window.P21CommandPalette.app.menusFlat.push({
        action() {
            return;
        },
        caption: 'Command Palette Version',
        id: 'p21-palette-app-version',
        description: P21CommandPalette.version,
        searchTerm: 'version',
    });

    // Enable / Disable WWMS
    
    for (const i in window.P21CommandPalette.app.menusFlat) {
        // if (i > 25) {
        //     continue;
        // }
        const menu = window.P21CommandPalette.app.menusFlat[i];


        const menuEl = document.createElement('div');
        menuEl.classList.add('palette-result');
        menuEl.tabIndex = 0;
        menuEl.id = `command-palette-result--${menu.id}`;
        menuEl.setAttribute('data-menu-id', menu.id);
        menuEl.setAttribute('data-menu-key', i);
        if (i % 2 === 0) {
            menuEl.classList.add('result-even');
        }

        menuEl.innerHTML = `
            <div id="command-palette-result--caption-${menu.id}">
                ${menu.caption.replace('[', '<br /><small style="opacity: 0.85">').replace(']', '</small>')}
            </div>
            <div id="command-palette-result--description-${menu.id}" style="font-size: 0.75em; opacity: 0.5;">
                ${menu.description}
            </div>
        `; 

        menuEl.addEventListener('click', () => menu.action());
        menuEl.__P21CommandMenuItem = menu;
        commandPaletteResultsEl.appendChild(menuEl);
        window.P21CommandPalette.filteredMenus.push({
            el: menuEl,
            id: menu.id,
        });
    }


    function setupCommandPaletteSearch () {
        if (typeof window.P21CommandPalette === 'undefined') {
            window.P21CommandPalette = {};
        }

        const fuseOptions = {
            // isCaseSensitive: false,
            // includeScore: false,
            includeScore: true,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            // threshold: 0.6,
            threshold: 0.4,
            // distance: 100,
            distance: 10,
            // useExtendedSearch: false,
            // ignoreLocation: false,
            // ignoreFieldNorm: false,
            keys: [
                "searchTerm",
            ]
        };

        window.P21CommandPalette.fuse = new Fuse(window.P21CommandPalette.app.menusFlat, fuseOptions);
    }

    loadFuse(setupCommandPaletteSearch);
    P21CommandPalette.injected = true;
    P21CommandPaletteNotification('Command Palette Installed', 1500);
};

try {
    addP21CommandPalette();
} catch (error) {
    //
}

console.log('Initializing event listeners…');
[
    'DOMContentLoaded',
    'load',
    'readystatechange',
    'beforeunload',
    'unload',
    'hashchange',
    'onpopstate',
    'unload',
    'pageshow',
    'pagehide',
].forEach(eventName => {
    console.log(`Initializing listener for ${eventName}…`);
    window.addEventListener(eventName, event => {

        console.warn({
            eventName,
            event,
        });
    });
});

window.addEventListener('hashchange', event => {
    console.log(P21CommandPalette);
    if (P21CommandPalette.injected) {
        console.log('P21CommandPalette already injected!');
        return;
    }

    console.log('Injecting P21CommandPalette…');
    P21CommandPalette.init.attemptCount = 0;

    addP21CommandPalette();

});
// window.addEventListener('DOMContentLoaded', (event) => {
//     console.log(`DOMContentLoaded: ${document.readyState}`);
//     // addP21CommandPalette();
// });
// window.addEventListener('load', (event) => {
//     console.log(`load: ${document.readyState}`);
//     // addP21CommandPalette();
// });
// document.addEventListener('readystatechange', (event) => {
//     console.log(`readystate: ${document.readyState}`);
//     // addP21CommandPalette();
// });
// 


export default addP21CommandPalette;