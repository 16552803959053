export default () => {
    const styleId = 'p21-command-palette-styles';

    if (document.getElementById(styleId) != null) {
        console.warn('Unable to add stylesheet, it already exists!');
        return false;
    }

    const styleEl = document.createElement('style');
    styleEl.id = styleId;
    styleEl.type = 'text/css';

    styleEl.innerHTML = `
        #command-palette--wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: none;
            z-index: 999999;
            background-color: rgba(0,0,0, 0.2);
        }

        #command-palette--container {
            display: flex; 
            justify-content: center; 
            align-content: center; 
            width: 100%; 
            height: 100%; 
        }

        #command-palette--body {
            width: 100%; 
            max-width: 350px; 
            height: 30%; 
            border-radius: 12px; 
            background-color: rgba(40, 41, 25, 0.9); 
            box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0.12), 2px 2px 15px 5px rgba(0,0,0,0.12); 
            color: white; 
            display: flex; 
            center-content: center; 
            margin-top: 24px; 
            padding: 8px;
            flex-direction: column;
            position: relative;
            padding-top: 24px;
        }

        #command-palette--results {
            display: flex; 
            padding-bottom: 8px;
            flex-direction: column;
            overflow-y: auto;
            border-radius: 12px; 
            box-shadow: inset -1px 0px 10px 5px rgba(0,0,0,0.36);
            height: 100%;
        }

        .result-hidden {
            display: none;
        }

        .palette-result.result-even {
            background-color: rgba(255,255,255, 0.0625);
        }

        .palette-result:hover {
            background-color: rgba(0,0,0, 0.1); 
            cursor: pointer;
        }


        .palette-result.result-even:hover {
            background-color: rgba(0,0,0, 0.2); 
            cursor: pointer;
        }

        .palette-result {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 8px;
            padding-right: 8px;
        }

        .palette-result:focus {
            outline: 2px solid rgba(255,255,255,0.5);
            outline-offset: -4px;
        }

        .palette-header {
            font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
            color: white;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            font-size: 11px;
            background-color: rgba(0,0,0,0.5);
            padding: 4px;
            line-height: 1;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
        }

        #command-palette--results::-webkit-scrollbar {
            width: 12px;
        }

        #command-palette--results::-webkit-scrollbar-track {
            background: rgba(255,255,255, 0.125);
            border-radius: 12px;
            margin-top: 3px;
            margin-bottom: 3px;
        }

        #command-palette--results::-webkit-scrollbar-thumb {
            background: rgba(255,255,255, 0.125);
            border-radius: 12px;
        }

        #command-palette--results::-webkit-scrollbar-thumb:hover {
            background: rgba(255,255,255, 0.25);
        }

        #command-palette--search {
            margin-top: 4px;
            margin-bottom: 9px;
            position: relative;
        }

        #command-palette--search input[type=search] {
            color: inherit;
            font: inherit;
            margin: 0;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            background-color: transparent;
            border-radius: 12px;
            appearance: none;
            border: none;
            box-shadow: inset -1px 0px 10px 5px rgb(0 0 0 / 36%);
            border-width: 2px;
            border-style: solid;
            border-color: transparent;
        }

        #command-palette--search input[type=search]:focus, #command-palette--search input[type=search]:focus-visible {
            border-radius: 12px;
            background-color: rgba(0, 0, 0, 0.25);
            border-color: rgba(255,255,255,0.5);
            border-style: solid;
            outline: none;
        }

        #command-palette-search--status {
            font-size: 12px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-content: center;
            align-items: center;
            line-height: 1;
            padding-right: 12px;
            opacity: 0.5;
        }

        #command-palette--search input[type=search]:focus, #command-palette--search input[type=search]:focus-visible {
            border-radius: 12px;
            background-color: rgba(0, 0, 0, 0.25);
            border-color: rgba(255,255,255,0.5);
            border-style: solid;
            outline: none;
        }

        .animate.shake {
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97);
        }

        @keyframes shake {
            10%, 90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%, 80% {
                transform: translate3d(2px, 0, 0);
            }

            30%, 50%, 70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%, 60% {
                transform: translate3d(4px, 0, 0);
            }
        }

        #perfumery-p21-inject--title {
            position: absolute;
            display: flex;
            justify-content: center;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            align-content: center;
            align-items: center;
            line-height: 1;
            font-size: 2rem;
            color: white;
            font-family: serif;
            font-weight: 600;
            letter-spacing: 1px;
        }

        #perfumery-p21-inject--title div {
            background-color: red;
            padding: 4px;
            border-radius: 0.25rem;
            margin-left: 4px;
        }

        .play-nav {
            background: #e91e1e;
            background: linear-gradient(to right,#e91e1e 0, #109ad3 100%);
        }

        .play-main-menu {
            background: #732626;
            background: linear-gradient(to right,#732626 0, #094056 100%);
        }

        .play-portal-nav {
            background: #732626 !important;
        }

        #perfumery-p21-inject--play-overlay {
            background-color: transparent;
            background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ff0000' fill-opacity='0.25' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
            center/auto 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            display: flex;
            justify-content: center;
        }

        #perfumery-p21-inject--play-overlay div {
            font-size: 4rem;
            color: white;
            margin-top: 2em;
            background-color: rgba(255, 0, 0, 0.75);
            margin-bottom: auto;
            line-height: 1;
            padding: 0.25em;
        }
    `;

    document.head.append(styleEl);
}