import defaultsDeep from 'lodash/defaultsDeep';

export default class P21CommandPalette {
    constructor(options = {}) {
        this.options = defaultsDeep(options, {
            mountMaxAttempts: 10,
            mountRetryAfterMs: 250,
            baseId: 'p21-command-palette',
        });

        this.options.mountAttemptCount = 0;
    }
}