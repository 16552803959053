export default function flattenMenus (menus, flattened = [], depth = 1, counter = 0) {
    counter++;
    for(const menu of menus) {
        // console.log({
        //     caption: menu.caption,
        //     subMenus: menu.menuitems.length,
        //     depth,
        //     counter,
        // });
        if (menu.menuitems.length > 0) {
            flattened = flattenMenus(menu.menuitems, flattened, depth + 1, counter);
        } else {
            flattened.push(menu)
        }

    }

    return flattened;
}